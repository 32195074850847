import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./AuctionPage.module.css";
import logo from "../assets/fanup_logo_white.png";
import CustomNavbar from "./CustomNavbar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import fetchWrapper from "../utils/fetchWrapper";

const MySwal = withReactContent(Swal);

const TournamentAuctionPage = () => {
  const navigate = useNavigate();

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [players, setPlayers] = useState([]);

  const { tournamentName } = useParams();
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);

  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);

  const extractSheetIdFromLink = (link) => {
    const match = link.match(/\/d\/([a-zA-Z0-9-_]+)/);
    return match ? match[1] : null;
  };

  const parseCSVData = (csv) => {
    const rows = csv.split("\n").slice(1); // Skip the first row (headers)
    const teams = [];
    let currentTeam = null;

    const playersTemp = [];

    rows.forEach((row, index) => {
      const columns = row
        .split(",")
        .map((col) => col.trim().replace(/^"|"$/g, "")); // Trim spaces and remove surrounding quotes

      let sports = "";

      if (columns.length > 4) {
        sports = columns.slice(4).join(",");
      }

      playersTemp.push({
        fullName: columns[0],
        playerEmail: columns[2],
        sports: sports
      });

    });

    setPlayers(playersTemp);

    return teams;
  };

  const fetchTournamentAndSheetData = async () => {

    try {
      setLoading(true);

      const tournamentResponse = await fetchWrapper(
        `${BACKEND_URL}/tournament/${tournamentName}`,
        {
          credentials: "include",
        }
      );

      if (tournamentResponse.ok) {
        const tournamentData = await tournamentResponse.json();
        const playerSheetLink = tournamentData.playerSheet;

        if (playerSheetLink) {
          const sheetId = extractSheetIdFromLink(playerSheetLink);
          const sheetName = "Sheet1";
          const url = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;

          const sheetResponse = await fetchWrapper(url);

          if (sheetResponse.ok) {
            const csvData = await sheetResponse.text();
            const teamsData = parseCSVData(csvData);
            setTeams(teamsData);
            setLoading(false);
          } else {
            throw new Error("Failed to fetch Google Sheet data");
          }
        }
      } else {
        throw new Error("Failed to fetch tournament data");
      }
    } catch (error) {
      console.error("Error fetching tournament or Google Sheet data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTournamentAndSheetData();
  }, [navigate]);

  // useEffect(() => {
  //   let playersTemp = [];

  //   if (teams.length > 0) {
  //     for (const team of teams) {
  //       const players = team.players;
  //       if (players && players.length > 0) {
  //         for (const player of players) {
  //           playersTemp.push({
  //             fullName: player.name,
  //             price: player.price,
  //             playerEmail: player.email,
  //             sports: player.sports,
  //             teamName: team.teamName
  //           });
  //         }
  //       }
  //     }
  //   }

  //   setPlayers(playersTemp);
  // }, [teams]);

  console.log(teams);
  console.log(players);

  const goToAuctionPage = () => {
    navigate("/dashboard", { state: { tournamentName: tournamentName } });
  };
  const goToBudgetSheet = () => {
    navigate(`/livebudgetsheet/${tournamentName}`, {
      state: { tournamentName: tournamentName },
    });
  };

  const goToNextPlayer = () => {
    setCurrentPlayerIndex((prevIndex) => (prevIndex + 1) % players.length);
    console.log(currentPlayerIndex);
  };

  const goToPreviousPlayer = () => {
    setCurrentPlayerIndex(
      (prevIndex) => (prevIndex - 1 + players.length) % players.length
    );
  };

  return (
    <div className={styles.auctionPage}>
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt='FanUp Logo' className={styles.logo} />
          <span className={styles.registrationText}>AUCTION PAGE</span>
        </div>
      </nav>
      <CustomNavbar />

      <div className={styles.auctionContainer}>
        {players.length > 0 && (
          <div className={styles.playerDetails}>
            <div className={styles.nameButtons}>
              <div className={styles.playerNameContainer}>
                <span className={styles.playerName}>
                  {players[currentPlayerIndex].fullName
                    .split(" ")
                    .map((part, index) => (
                      <span
                        key={index}
                        className={
                          index % 2 === 0
                            ? styles.regularNamePart
                            : styles.boldNamePart
                        }
                      >
                        {part}{" "}
                      </span>
                    ))}
                </span>
              </div>

              <div className={styles.buttons}>
                <button
                  onClick={goToPreviousPlayer}
                  className={styles.prevButton}
                >
                  Previous
                </button>
                <button onClick={goToNextPlayer} className={styles.nextButton}>
                  Next
                </button>
              </div>
            </div>

            <div className={styles.playerInfo}>

              {/* <p className={styles.title}>Student ID</p>
              <p className={styles.titleanswer}>
                {players[currentPlayerIndex].studentIdNumber}
              </p> */}

              <p className={styles.title}>Email ID</p>
              <p className={styles.titleanswer}>
                {players[currentPlayerIndex].playerEmail}
              </p>

              {/* <p className={styles.title}>Phone Number</p>
              <p className={styles.titleanswer}>
                {players[currentPlayerIndex].phoneNumber}
              </p> */}

              <p style={{marginTop: '10px'}} className={styles.title}>Sports</p>
              <p className={styles.titleanswer}>
                {players[currentPlayerIndex].sports}
              </p>

              <p style={{marginTop: '10px'}} className={styles.title}>Base Price</p>
              <p className={styles.titleanswer}>
                1M
              </p>

              {/* <p className={styles.title}>Team</p>
              <p className={styles.titleanswer}>
                {players[currentPlayerIndex].teamName}
              </p> */}

              {/* <p className={styles.title}>Player Price</p>
              <p className={styles.titleanswer}>
                {players[currentPlayerIndex].price}
              </p> */}

            </div>
          </div>
        )}
        <div className={styles.auctionControls}>
          <button onClick={goToAuctionPage} className={styles.saveButton}>
            Back to Dashboard
          </button>
          <button onClick={goToBudgetSheet} className={styles.saveButton}>
            Live Budget Sheet
          </button>
        </div>
      </div>
    </div>
  );
};

export default TournamentAuctionPage;
