import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Papa from "papaparse";
import moment from "moment";

import styles from './../TournamentPage.module.css';
import logo from "../../assets/fanup_logo_white.png";
import CustomNavbar from "../CustomNavbar";
import UpdateLive from "../UpdatingLive";
import fetchWrapper from "../../utils/fetchWrapper";

const TournamentStats = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const { tournamentId } = useParams();
  const [loading, setLoading] = useState(false);

  const [allItems, setAllItems] = useState([]);
  const [items, setItems] = useState([]);

  const [sport, setSport] = useState("");
  const [team, setTeam] = useState("");
  const [stat, setStat] = useState("");

  const [sports, setSports] = useState([]);
  const [teams, setTeams] = useState([]);
  const [stats, setStats] = useState([]);

  useEffect(() => {
    if (!tournamentId) {
      console.error("No tournament found");
      navigate("/");
      return;
    }

    const fetchTournamentAndSheetData = async () => {
      try {
        setLoading(true);
        const tournamentResponse = await fetchWrapper(
          `${BACKEND_URL}/tournament/${tournamentId}`,
          {
            credentials: "include",
          }
        );
        if (tournamentResponse.ok) {
          const tournamentData = await tournamentResponse.json();
          //change sheet thing here
          let sheetLink = tournamentData.results;
          //just for demo  
          // sheetLink = "https://docs.google.com/spreadsheets/d/1Oa89tb3UQIN64mSy3H8yskJscDZ4HrDx8XF2vd2t0Ow/edit?usp=sharing";

          if (sheetLink) {
            const sheetId = extractSheetIdFromLink(sheetLink);
            const sheetName = "Sheet1";
            const url = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;
            const sheetResponse = await fetchWrapper(url);
            if (sheetResponse.ok) {
              const csvData = await sheetResponse.text();
              setAllItems(parseCSVData(csvData));
              setLoading(false);
            } else {
              throw new Error("Failed to fetch Google Sheet data");
            }
          }

        } else {
          throw new Error("Failed to fetch tournament data");
        }
      } catch (error) {
        console.error("Error fetching tournament or Google Sheet data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTournamentAndSheetData();

    const interval = setInterval(() => {
      fetchTournamentAndSheetData();
    }, 30000);

    return () => clearInterval(interval);
  }, [navigate, location]);

  const extractSheetIdFromLink = (link) => {
    const match = link.match(/\/d\/([a-zA-Z0-9-_]+)/);
    return match ? match[1] : null;
  };

  const parseCSVData = (csv) => {
    const parsedData = Papa.parse(csv, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true
    });
    const entries = [];
    for (const item of parsedData.data) {

      let matchStatus = "scheduled";
      let matchStartFrom = moment(item["From"], "MM/DD/YYYY, HH:mm");
      let matchStartTo = moment(item["To"], "MM/DD/YYYY, HH:mm");
      let now = moment();

      // Determine match status
      if (now.isAfter(matchStartTo)) {
        matchStatus = "ended";
      } else if (now.isBetween(matchStartFrom, matchStartTo, null, "[)")) {
        matchStatus = "started";
      }

      const startDateDisplay = matchStartFrom.format("DD MMM YYYY, hh:mm A");
      const endDateDisplay = matchStartTo.format("DD MMM YYYY, hh:mm A");

      let timeToDisplay = startDateDisplay + " - " + endDateDisplay;
      if (matchStartFrom.isSame(matchStartTo, "day")) {
        timeToDisplay = matchStartFrom.format("D MMM YYYY") +
          ", " + matchStartFrom.format("hh:mm A") +
          " - " + matchStartTo.format("hh:mm A");
      }

      entries.push({
        univerisity: item["University"],
        player: item["Player Name"],
        sportName: item["Sport Name"],
        score: item["Score"],
        stat: item["Stats"]
      });
    }
    return entries;
  };

  useEffect(() => {

    const nSports = [];
    const nTeams = [];
    const nStats = [];

    for (const itemSingle of allItems) {

      if (!nSports.includes(itemSingle.sportName)) {
        nSports.push(itemSingle.sportName);
      }

      if (!nTeams.includes(itemSingle.univerisity)) {
        nTeams.push(itemSingle.univerisity);
      }

      if (!nStats.includes(itemSingle.stat)) {
        nStats.push(itemSingle.stat);
      }

    }

    setSports(nSports);
    setTeams(nTeams);
    setStats(nStats);

    applyFilters();
  }, [allItems]);

  const applyFilters = () => {
    const nItems = [];
    for (const itemSingle of allItems) {
      let show = true;

      if (sport !== "") {
        if (itemSingle.sportName != sport) {
          show = false;
        }
      }

      if (team !== "") {
        if (itemSingle.univerisity != team) {
          show = false;
        }
      }

      if (stat !== "") {
        if (itemSingle.stat != stat) {
          show = false;
        }
      }

      if (show) {
        nItems.push(itemSingle);
      }
    }
    setItems(nItems);
  }

  return (
    <div className={`${styles.tournamentPage} ss-page`}>
      {loading && <UpdateLive loadingTxt="Refreshing..." />}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt='FanUp Logo' className={styles.logo} />
          <span className={styles.registrationText}>STATS</span>
        </div>
      </nav>
      <CustomNavbar />

      <div className={`${styles.dashboardGrid} ss-box-container`}>

        <div className="ss-box">

          <div className="ss-box-filters">
            <div className="filter-item">
              <select value={sport} onChange={((e) => setSport(e?.target?.value ?? ""))} className="form-control">
                <option value="">All Sports</option>
                {
                  sports.map((sport, index) => {
                    return <option key={index} value={sport}>{sport}</option>
                  })
                }
              </select>
            </div>
            <div className="filter-item">
              <select value={team} onChange={((e) => setTeam(e?.target?.value ?? ""))} className="form-control">
                <option value="">All Teams</option>
                {
                  teams.map((team, index) => {
                    return <option key={index} value={team}>{team}</option>
                  })
                }
              </select>
            </div>
            <div className="filter-item">
              <select value={stat} onChange={((e) => setStat(e?.target?.value ?? ""))} className="form-control">
                <option value="">All Stats</option>
                {
                  stats.map((stat, index) => {
                    return <option key={index} value={stat}>{stat}</option>
                  })
                }
              </select>
            </div>
            <div className="filter-item">
              <button onClick={applyFilters} className="ss-box-filter-btn">Submit</button>
            </div>
          </div>

          <div className="ss-box-content">

            {
              items.length > 0 ? <>
                <div className="ss-schedule-items player-items">

                  {
                    items.map((item, index) => {
                      return <div className="ss-schedule-item player-item" key={index}>
                        <span className="sno">{index + 1}.</span>
                        <h4 className="player-name">{item.player}</h4>
                        <h4 className="university">{item.univerisity}</h4>
                        <h4 className="score"><span className="label">Score:</span><span>{item.score}</span></h4>
                      </div>
                    })
                  }

                </div>
              </> : <>
                <p className="text-center">No Data Found</p>
              </>
            }

          </div>

        </div>

      </div>

    </div>
  );
};


export default TournamentStats;