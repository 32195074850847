import React from 'react';
import styles from './UpdateLive.module.css';

const LiveSpinner = ({ loadingTxt = "Live update in progress..." }) => {
  return (
    <div className={styles.spinnerOverlay}>
      <div className={styles.spinner}></div>
      <p className={styles.text}>{loadingTxt}</p>
    </div>
  );
};

export default LiveSpinner;
