import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Papa from "papaparse";
import moment from "moment";

import styles from "./../TournamentPage.module.css";
import logo from "../../assets/fanup_logo_white.png";
import CustomNavbar from "./../CustomNavbar";
import UpdateLive from "./../UpdatingLive";
import fetchWrapper from "./../../utils/fetchWrapper";

const TournamentSchedule = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const { tournamentId } = useParams();
  const [loading, setLoading] = useState(false);

  const [allItems, setAllItems] = useState([]);
  const [items, setItems] = useState([]);

  const [sport, setSport] = useState("");
  const [team, setTeam] = useState("");
  const [date, setDate] = useState("");
  const [group, setGroup] = useState("");
  const [tournamentLogo, setTournamentLogo] = useState("");

  const [sports, setSports] = useState([]);
  const [teams, setTeams] = useState([]);
  const [dates, setDates] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (!tournamentId) {
      console.error("No tournament found");
      navigate("/");
      return;
    }

    const fetchTournamentAndSheetData = async () => {
      try {
        setLoading(true);
        const tournamentResponse = await fetchWrapper(
          `${BACKEND_URL}/tournament/${tournamentId}`,
          {
            credentials: "include",
          }
        );
        if (tournamentResponse.ok) {
          const tournamentData = await tournamentResponse.json();
          //change sheet thing here
          setTournamentLogo(tournamentData.logo);
          let sheetLink = tournamentData.schedule;
          //just for demo
          // sheetLink = "https://docs.google.com/spreadsheets/d/1jGveiAXkdieksvw5j_w6zeNrPLchii67gL3hbD6Ebws/edit?gid=0#gid=0";

          if (sheetLink) {
            const sheetId = extractSheetIdFromLink(sheetLink);
            const sheetName = "Sheet1";
            const url = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;
            const sheetResponse = await fetchWrapper(url);
            if (sheetResponse.ok) {
              const csvData = await sheetResponse.text();
              setAllItems(parseCSVData(csvData));
              setLoading(false);
            } else {
              throw new Error("Failed to fetch Google Sheet data");
            }
          }
        } else {
          throw new Error("Failed to fetch tournament data");
        }
      } catch (error) {
        console.error("Error fetching tournament or Google Sheet data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTournamentAndSheetData();

    const interval = setInterval(() => {
      fetchTournamentAndSheetData();
    }, 30000);

    return () => clearInterval(interval);
  }, [navigate, location]);

  const extractSheetIdFromLink = (link) => {
    const match = link.match(/\/d\/([a-zA-Z0-9-_]+)/);
    return match ? match[1] : null;
  };

  const parseCSVData = (csv) => {
    const parsedData = Papa.parse(csv, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
    });
    const entries = [];
    for (const item of parsedData.data) {
      const hasValidTime = (dateStr) => {
        if (!dateStr) return false;
        return dateStr.includes(",") && dateStr.split(",")[1].trim().length > 0;
      };

      const fromHasTime = hasValidTime(item["From"]);
      const toHasTime = hasValidTime(item["To"]);

      let matchStartFrom = fromHasTime
        ? moment(item["From"], "MM/DD/YYYY, HH:mm")
        : item["From"]
        ? moment(item["From"], "MM/DD/YYYY")
        : null;

      let matchStartTo = toHasTime
        ? moment(item["To"], "MM/DD/YYYY, HH:mm")
        : item["To"]
        ? moment(item["To"], "MM/DD/YYYY")
        : null;

      let matchStatus = "scheduled";
      let now = moment();

      console.log(matchStartTo, "matchStartTo-----");

      // Determine match status
      if (now.isAfter(matchStartTo)) {
        matchStatus = "ended";
      } else if (now.isBetween(matchStartFrom, matchStartTo, null, "[)")) {
        matchStatus = "started";
      }

      const formatDateDisplay = (momentObj, hasTime) => {
        if (!momentObj) return "";
        return hasTime
          ? momentObj.format("DD MMM YYYY, hh:mm A")
          : momentObj.format("DD MMM YYYY");
      };

      const startDateDisplay = formatDateDisplay(matchStartFrom, fromHasTime);
      const endDateDisplay = formatDateDisplay(matchStartTo, toHasTime);

      let timeToDisplay = "";

      if (matchStartFrom && matchStartTo) {
        if (matchStartFrom.isSame(matchStartTo, "day")) {
          if (fromHasTime && toHasTime) {
            timeToDisplay = `${matchStartFrom.format(
              "D MMM YYYY"
            )}, ${matchStartFrom.format("hh:mm A")} - ${matchStartTo.format(
              "hh:mm A"
            )}`;
          } else {
            timeToDisplay = matchStartFrom.format("D MMM YYYY");
          }
        } else {
          timeToDisplay = endDateDisplay
            ? `${startDateDisplay} - ${endDateDisplay}`
            : startDateDisplay;
        }
      } else if (matchStartFrom) {
        timeToDisplay = startDateDisplay;
      }

      const startDate = matchStartFrom
        ? matchStartFrom.format("DD MMM YYYY")
        : "";

      entries.push({
        univerisityOne: item["University 1"],
        univerisityTwo: item["University 2"],
        sportName: item["Sport Name"],
        univerisityOneScore: item["University 1 Score"],
        univerisityTwoScore: item["University 2 Score"],
        group: item["Group"],
        time: timeToDisplay,
        startDate: startDate,
        status: matchStatus,
      });
    }
    return entries;
  };

  useEffect(() => {
    const nSports = [];
    const nTeams = [];
    const nDates = [];
    const nGroups = [];

    for (const itemSingle of allItems) {
      if (!nSports.includes(itemSingle.sportName)) {
        nSports.push(itemSingle.sportName);
      }

      if (itemSingle.startDate && !nDates.includes(itemSingle.startDate)) {
        nDates.push(itemSingle.startDate);
      }

      if (!nTeams.includes(itemSingle.univerisityOne)) {
        nTeams.push(itemSingle.univerisityOne);
      }

      if (!nTeams.includes(itemSingle.univerisityTwo)) {
        nTeams.push(itemSingle.univerisityTwo);
      }

      if (
        itemSingle.group &&
        itemSingle.group.trim() !== "" &&
        !nGroups.includes(itemSingle.group)
      ) {
        nGroups.push(itemSingle.group);
      }
    }

    nDates.sort((a, b) =>
      moment(a, "DD MMM YYYY").diff(moment(b, "DD MMM YYYY"))
    );

    setSports(nSports);
    setTeams(nTeams);
    setDates(nDates);
    setGroups(nGroups);

    applyFilters();
  }, [allItems]);

  const applyFilters = () => {
    const nItems = [];
    for (const itemSingle of allItems) {
      let show = true;

      if (sport !== "") {
        if (itemSingle.sportName != sport) {
          show = false;
        }
      }

      if (team !== "") {
        if (
          itemSingle.univerisityOne != team &&
          itemSingle.univerisityTwo != team
        ) {
          show = false;
        }
      }

      if (date !== "") {
        if (itemSingle.startDate !== date) {
          show = false;
        }
      }

      if (group !== "") {
        if (itemSingle.group !== group) {
          show = false;
        }
      }

      if (show) {
        nItems.push(itemSingle);
      }
    }
    setItems(nItems);
  };

  return (
    <div className={`${styles.tournamentPage} ss-page`}>
      {loading && <UpdateLive loadingTxt='Refreshing...' />}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt='FanUp Logo' className={styles.logo} />
          <span className={styles.registrationText}>SCHEDULE</span>
        </div>
        {tournamentLogo ? (
          <>
            <img
              className={styles.tournamentLogo}
              src={tournamentLogo}
              alt='Tournament Logo'
            />
          </>
        ) : null}
      </nav>
      <CustomNavbar />

      <div className={`${styles.dashboardGrid} ss-box-container`}>
        <div className='ss-box'>
          <div className='ss-box-filters'>
            {/* Sports Filter */}
            <div className='filter-item'>
              <select
                value={sport}
                onChange={(e) => setSport(e?.target?.value ?? "")}
                className='form-control'
              >
                <option value=''>All Sports</option>
                {sports.map((sport, index) => {
                  return (
                    <option key={index} value={sport}>
                      {sport}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* Team Filter */}
            <div className='filter-item'>
              <select
                value={team}
                onChange={(e) => setTeam(e?.target?.value ?? "")}
                className='form-control'
              >
                <option value=''>All Teams</option>
                {teams.map((team, index) => {
                  return (
                    <option key={index} value={team}>
                      {team}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* Date Filter */}
            <div className='filter-item'>
              <select
                value={date}
                onChange={(e) => setDate(e?.target?.value ?? "")}
                className='form-control'
              >
                <option value=''>All Dates</option>
                {dates.map((date, index) => {
                  return (
                    <option key={index} value={date}>
                      {date}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* Groups Filter */}
            <div className='filter-item'>
              <select
                value={group}
                onChange={(e) => setGroup(e?.target?.value ?? "")}
                className='form-control'
              >
                <option value=''>All Groups</option>
                {groups.map((group, index) => {
                  return (
                    <option key={index} value={group}>
                      {group}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className='filter-item'>
              <button onClick={applyFilters} className='ss-box-filter-btn'>
                Submit
              </button>
            </div>
          </div>

          <div className='ss-box-content'>
            {items.length > 0 ? (
              <>
                <div className='ss-schedule-items'>
                  {items.map((item, index) => {
                    return (
                      <div className='ss-schedule-item' key={index}>
                        <div className='team-left'>
                          <h4>{item?.univerisityOne}</h4>
                        </div>
                        <div className='match-info'>
                          <div className='head'>
                            <span className='date-time'>{item?.time}</span>
                            <span className='location'>{item?.sportName}</span>
                          </div>
                          <div className='middle'>
                            <span>
                              {item?.univerisityOneScore} -{" "}
                              {item?.univerisityTwoScore}
                            </span>
                          </div>
                          <div className='foot'>
                            <span className={`match-status ${item?.status}`}>
                              {item?.status}
                            </span>
                            <br></br>
                            <span className='group-name'>
                              Group - {item.group}
                            </span>
                          </div>
                        </div>
                        <div className='team-right'>
                          <h4>{item?.univerisityTwo}</h4>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <>
                <p className='text-center'>No Data Found</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentSchedule;
