import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import styles from "./TournamentDashboard.module.css";
import logo from "../assets/fanup_logo_white.png";
import { saveAs } from "file-saver";
import CustomNavbar from "./CustomNavbar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingSpinner from "./LoadingSpinner";

import fetchWrapper from "./../utils/fetchWrapper";

const TournamentDashboard = () => {
  const MySwal = withReactContent(Swal);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [collegeName, setCollegeName] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    playerFormFields: [],
    teamFormFields: [],
    isPlayerRegistrationEnabled: true,
    isTeamRegistrationEnabled: true,
  });

  const toggleRegistration = (type) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [type === "player"
        ? "isPlayerRegistrationEnabled"
        : "isTeamRegistrationEnabled"]:
        !prevFormData[
          type === "player"
            ? "isPlayerRegistrationEnabled"
            : "isTeamRegistrationEnabled"
        ],
    }));
  };

  const addCustomField = (role) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`${role}FormFields`]: [
        ...(prevFormData[`${role}FormFields`] || []),
        { label: "", type: "text" },
      ],
    }));
  };
  const removeCustomField = (role, index) => {
    setFormData((prevFormData) => {
      const updatedFields = [...prevFormData[`${role}FormFields`]];
      updatedFields.splice(index, 1); // Remove field at specified index
      return { ...prevFormData, [`${role}FormFields`]: updatedFields };
    });
  };

  const handleCustomFieldChange = (role, index, key, value) => {
    const fields = [...formData[`${role}FormFields`]];
    fields[index][key] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`${role}FormFields`]: fields,
    }));
  };

  const sportsOptions = [
    { value: "Basketball", label: "Basketball" },
    { value: "Football", label: "Football" },
    { value: "Tennis", label: "Tennis" },
    { value: "Badminton", label: "Badminton" },
    { value: "Cricket", label: "Cricket" },
    { value: "Pool", label: "Pool" },
    { value: "Squash", label: "Squash" },
    { value: "Table-Tennis", label: "Table-Tennis" },
    { value: "Chess", label: "Chess" },
    { value: "Volleyball", label: "Volleyball" },
    { value: "Powerlifting", label: "Powerlifting" },
    { value: "Swimming", label: "Swimming" },
  ];

  const handleDownloadTeams = async () => {
    if (!selectedTournament) {
      alert("Please select a tournament first.");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetchWrapper(
        `${BACKEND_URL}/download-team-list/${selectedTournament.tournamentName}`,
        {
          method: "GET",
          credentials: "include",
          responseType: "blob",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download team list");
      }

      const blob = await response.blob();
      saveAs(blob, `teams_${selectedTournament.tournamentName}.xlsx`);
      setIsLoading(false);
      MySwal.fire({
        title: "Success",
        text: "Teams downloaded successfully",
        icon: "success",
        confirmButtonText: "OK",
        color: "#fff",
        background: "#333",
        theme: "dark",
        backdrop: true,
      });
    } catch (error) {
      console.error("Error downloading the team list:", error);
    }
  };
  const handleDownload = async () => {
    if (!selectedTournament) {
      alert("Please select a tournament first.");
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetchWrapper(
        `${process.env.REACT_APP_BACKEND_URL}/download-player-list/${selectedTournament.tournamentName}`,
        {
          method: "GET",
          credentials: "include",
          responseType: "blob",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download player list");
      }

      const blob = await response.blob();
      saveAs(blob, `players_${selectedTournament.tournamentName}.xlsx`);
      setIsLoading(false);
      MySwal.fire({
        title: "Success",
        text: "Players downloaded successfully",
        icon: "success",
        confirmButtonText: "OK",
        color: "#fff",
        background: "#333",
        theme: "dark",
        backdrop: true,
      });
    } catch (error) {
      console.error("Error downloading the player list:", error);
    }
  };
  const goToAuctionPage = () => {
    navigate(`/livebudgetsheet/${selectedTournament.tournamentName}`, {
      state: { tournamentName: selectedTournament.tournamentName },
    });
  };

  useEffect(() => {
    const verifyAccess = async () => {
      const tournamentName = sessionStorage.getItem("tournamentName");
      if (!tournamentName) {
        return;
      }

      try {
        const response = await fetchWrapper(
          `${BACKEND_URL}/check-coordinator/${tournamentName}`,
          {
            method: "GET",
            credentials: "include", // Ensure cookies with the token are included
          }
        );

        if (!response.ok) {
          const result = await response.json();
          Swal.fire({
            title: "Access Denied",
            text: result.error || "You are not authorized to access this page.",
            icon: "error",
            color: "#fff",
            background: "#333",
          }).then(() => {
            navigate("/"); // Redirect after alert
          });
        }
      } catch (error) {
        console.error("Error verifying access:", error);
        navigate("/"); // Redirect on error
      }
    };

    verifyAccess();
  }, [BACKEND_URL, navigate]);

  useEffect(() => {
    const fetchTournament = async () => {
      const collegeName = sessionStorage.getItem("collegeName");
      const tournamentName = sessionStorage.getItem("tournamentName");
      if (!collegeName) {
        navigate("/");
        return;
      }

      try {
        // Check for Inter-University Tournament
        const interResponse = await fetchWrapper(
          `${BACKEND_URL}/check-inter-university-tournament/${collegeName}`,
          {
            credentials: "include",
          }
        );

        // Check for Intra-University Tournament
        const intraResponse = await fetchWrapper(
          `${BACKEND_URL}/check-intra-university-tournament/${collegeName}/${tournamentName}`,
          {
            credentials: "include",
          }
        );

        const interData = interResponse.ok ? await interResponse.json() : null;
        const intraData = intraResponse.ok ? await intraResponse.json() : null;

        if (interData?.exists) {
          setSelectedTournament(interData.tournament);
          verifyCoordinatorAccess(interData.tournament.tournamentName);
        } else if (intraData?.exists) {
          setSelectedTournament(intraData.tournament);
          verifyCoordinatorAccess(intraData.tournament.tournamentName);
        } else {
          navigate("/create-tournament", { state: { collegeName } });
        }
      } catch (error) {
        console.error("Error fetching tournament:", error);
        navigate("/");
      }
    };

    const verifyCoordinatorAccess = async (tournamentName) => {
      try {
        const response = await fetchWrapper(
          `${BACKEND_URL}/check-coordinator/${tournamentName}`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        if (!response.ok) {
          const result = await response.json();
          Swal.fire({
            title: "Access Denied",
            text: result.error || "You are not authorized to access this page.",
            icon: "error",
            color: "#fff",
            background: "#333",
          }).then(() => navigate("/"));
        }
      } catch (error) {
        console.error("Error verifying access:", error);
        navigate("/");
      }
    };

    fetchTournament();
  }, [navigate, BACKEND_URL]);

  useEffect(() => {
    if (selectedTournament) {
      setFormData({
        tournamentName: selectedTournament.tournamentName || "",
        sports: selectedTournament.sports || [],
        startDate: selectedTournament.startDate || "",
        endDate: selectedTournament.endDate || "",
        location: selectedTournament.location || "",
        logo: selectedTournament.logo || "",
        brochure: selectedTournament.brochure || "",
        numTeams: selectedTournament.numTeams || 0,
        numPlayers: selectedTournament.numPlayers || 0,
        numCisMen: selectedTournament.numCisMen || 0,
        numNonCisMen: selectedTournament.numNonCisMen || 0,
        registrationFees: selectedTournament.registrationFees || 0,
        verificationCode: selectedTournament.verificationCode || "",
        rules: selectedTournament.rules || "",
        schedule: selectedTournament.schedule || "",
        auction: selectedTournament.auction || "",
        results: selectedTournament.results || "",
        timeline: selectedTournament.timeline || "",
        sponsorList: selectedTournament.sponsorList || "",
        sponsorLogo: selectedTournament.sponsorLogo || "",
        gallery: selectedTournament.gallery || "",
        newsfeedLink: selectedTournament.newsfeedLink || "",
        playerFormFields: selectedTournament.playerFormFields || [], // Added playerFormFields
        teamFormFields: selectedTournament.teamFormFields || [], // Added teamFormFields
        isPlayerRegistrationEnabled:
          selectedTournament.isPlayerRegistrationEnabled || false,
        isTeamRegistrationEnabled:
          selectedTournament.isTeamRegistrationEnabled || false,
      });
    }
  }, [selectedTournament]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSportsChange = (selectedOptions) => {
    const selectedSports = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData({ ...formData, sports: selectedSports });
  };

  const handleSaveChanges = async () => {
    try {
      const response = await fetchWrapper(
        `${BACKEND_URL}/update-tournament/${selectedTournament.tournamentName}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
          credentials: "include",
        }
      );

      if (response.ok) {
        MySwal.fire({
          title: "Success",
          text: "Changes Saved Successfully!",
          icon: "success",
          color: "#fff",
          background: "#333",
        });
      } else {
        console.error("Failed to save changes");
      }
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleCreateTournament = () => {
    navigate("/create-tournament", { state: { collegeName } });
  };

  return (
    <div className={styles.tournamentDashboard}>
      {isLoading && <LoadingSpinner />}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt='FanUp Logo' className={styles.logo} />
          <span className={styles.registrationText}>TOURNAMENT DASHBOARD</span>
        </div>
      </nav>
      <CustomNavbar /> {/* Use the navbar component here */}
      <div className={styles.dashboardGrid}>
        <div className={styles.dashboardGroup}>
          <h3>TOURNAMENT NAME</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='tournamentName'
              value={formData.tournamentName}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter tournament name'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>SPORTS</h3>
          <div className={styles.dashboardItem}>
            <Select
              isMulti
              name='sports'
              options={sportsOptions}
              className={styles.adminInput}
              classNamePrefix='select'
              onChange={handleSportsChange}
              value={sportsOptions.filter((option) =>
                (formData.sports || []).includes(option.value)
              )}
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>START DATE</h3>
          <div className={styles.dashboardItem}>
            <input
              type='date'
              name='startDate'
              value={formData.startDate}
              onChange={handleChange}
              className={styles.transparentInput}
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>END DATE</h3>
          <div className={styles.dashboardItem}>
            <input
              type='date'
              name='endDate'
              value={formData.endDate}
              onChange={handleChange}
              className={styles.transparentInput}
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>LOCATION</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='location'
              value={formData.location}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter location'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>LOGO (LINK)</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='logo'
              value={formData.logo}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter logo link'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>BROCHURE (LINK)</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='brochure'
              value={formData.brochure}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter brochure link'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>NO. OF TEAMS</h3>
          <div className={styles.dashboardItem}>
            <input
              type='number'
              name='numTeams'
              value={formData.numTeams}
              onChange={handleChange}
              className={styles.transparentInput}
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>NO. OF PLAYERS</h3>
          <div className={styles.dashboardItem}>
            <input
              type='number'
              name='numPlayers'
              value={formData.numPlayers}
              onChange={handleChange}
              className={styles.transparentInput}
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>NO. OF CIS MEN</h3>
          <div className={styles.dashboardItem}>
            <input
              type='number'
              name='numCisMen'
              value={formData.numCisMen}
              onChange={handleChange}
              className={styles.transparentInput}
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>NO. OF NON-CIS MEN</h3>
          <div className={styles.dashboardItem}>
            <input
              type='number'
              name='numNonCisMen'
              value={formData.numNonCisMen}
              onChange={handleChange}
              className={styles.transparentInput}
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>NEWSFEED LINK</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='newsfeedLink'
              value={formData.newsfeedLink}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter newsfeed link'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>REGISTRATION FEES</h3>
          <div className={styles.dashboardItem}>
            <input
              type='number'
              name='registrationFees'
              value={formData.registrationFees}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter registration fees'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>VERIFICATION CODE</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='verificationCode'
              value={formData.verificationCode}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter verification code'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>RULES (LINK)</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='rules'
              value={formData.rules}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter rules link'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>SCHEDULE (LINK)</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='schedule'
              value={formData.schedule}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter schedule link'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>AUCTION (LINK)</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='auction'
              value={formData.auction}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter auction link'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>RESULTS (LINK)</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='results'
              value={formData.results}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter results link'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>TIMELINE (LINK)</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='timeline'
              value={formData.timeline}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter timeline link'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>SPONSOR NAMES</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='sponsorList'
              value={formData.sponsorList}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter sponsor list'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>SPONSOR LOGO (LINK)</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='sponsorLogo'
              value={formData.sponsorLogo}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter sponsor logo link'
            />
          </div>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>GALLERY (LINK)</h3>
          <div className={styles.dashboardItem}>
            <input
              type='text'
              name='gallery'
              value={formData.gallery}
              onChange={handleChange}
              className={styles.transparentInput}
              placeholder='Enter gallery link'
            />
          </div>
        </div>
        <div className={styles.dashboardGroup}>
          <h3>Additional Player Fields</h3>
          {(formData.playerFormFields || []).map((field, index) => (
            <div key={index} className={styles.customField}>
              <input
                type='text'
                placeholder='Field Label'
                value={field.label}
                onChange={(e) =>
                  handleCustomFieldChange(
                    "player",
                    index,
                    "label",
                    e.target.value
                  )
                }
                className={styles.transparentInput}
              />
              <select
                value={field.type}
                onChange={(e) =>
                  handleCustomFieldChange(
                    "player",
                    index,
                    "type",
                    e.target.value
                  )
                }
                className={styles.transparentInput}
              >
                <option value='text'>Text</option>
                <option value='number'>Number</option>
                <option value='file'>File</option>
                <option value='email'>Email</option>
                <option value='phone'>Phone Number</option>
              </select>
              <button
                onClick={() => removeCustomField("player", index)}
                className={styles.removeButton}
              >
                Remove
              </button>
            </div>
          ))}
          <button onClick={() => addCustomField("player")}>
            Add Player Field
          </button>
        </div>

        <div className={styles.dashboardGroup}>
          <h3>Additional Team Fields</h3>
          {(formData.teamFormFields || []).map((field, index) => (
            <div key={index} className={styles.customField}>
              <input
                type='text'
                placeholder='Field Label'
                value={field.label}
                onChange={(e) =>
                  handleCustomFieldChange(
                    "team",
                    index,
                    "label",
                    e.target.value
                  )
                }
                className={styles.transparentInput}
              />
              <select
                value={field.type}
                onChange={(e) =>
                  handleCustomFieldChange("team", index, "type", e.target.value)
                }
                className={styles.transparentInput}
              >
                <option value='text'>Text</option>
                <option value='number'>Number</option>
                <option value='file'>File</option>
                <option value='email'>Email</option>
                <option value='phone'>Phone Number</option>
              </select>
              <button
                onClick={() => removeCustomField("team", index)}
                className={styles.removeButton}
              >
                Remove
              </button>
            </div>
          ))}
          <button onClick={() => addCustomField("team")}>Add Team Field</button>
        </div>
        <div className={styles.dashboardGroup}>
          <h3>Enable/Disable Registration</h3>
          <div>
            <label>
              <input
                type='checkbox'
                checked={formData.isPlayerRegistrationEnabled}
                onChange={() => toggleRegistration("player")}
              />
              Player Registration
            </label>
            <label>
              <input
                type='checkbox'
                checked={formData.isTeamRegistrationEnabled}
                onChange={() => toggleRegistration("team")}
              />
              Team Registration
            </label>
          </div>
        </div>
      </div>
      <div className={styles.saveButtonContainer}>
        <button onClick={handleSaveChanges} className={styles.saveButton}>
          SAVE CHANGES
        </button>
        <button onClick={handleCreateTournament} className={styles.saveButton}>
          Create a New Tournament
        </button>
        <button onClick={handleDownload} className={styles.saveButton}>
          Download Player List
        </button>
        <button onClick={handleDownloadTeams} className={styles.saveButton}>
          Download Team List
        </button>
        <button onClick={goToAuctionPage} className={styles.saveButton}>
          Go to Live Budget Sheet and Auction
        </button>
      </div>
    </div>
  );
};

export default TournamentDashboard;
